import { HttpClient } from './share/rest/http.client'
import {
ProductService,
OrderService,
QrService,
TouchService,
} from './client/services'
export * from './client/services'
export * from './client/model'
export * from './share'
class NetworkLayer {
productService:ProductService
orderService:OrderService
qrService:QrService
touchService:TouchService
constructor(private httpClient: HttpClient) { 
this.productService = new ProductService(this.httpClient)
this.orderService = new OrderService(this.httpClient)
this.qrService = new QrService(this.httpClient)
this.touchService = new TouchService(this.httpClient)
}
}
export {  NetworkLayer }
