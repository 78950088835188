import { useHistory } from "react-router-dom";
import { Keyboard } from "../../features/keyboard/Keyboard";
import { TopLine } from '../../components/top-line/TopLine';
import { OrderStages } from '../../components/order-stages/OrderStages';
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    onBackspace,
    onChangeFocus,
    onKey,
    dontKnowImeiSwitch,
    dontKnowModelSwitch,
    dontKnowBrandSwitch,
    selectPhoneDataState,
    selectNecessaryPhoneDataFilled,
    selectImeiText,
    selectBrandText,
    selectModelText,
    selectBrandListShow,
    selectModelListShow,
    brandListOpener,
    modelListOpener,
    onEnter,
    selectBrands,
    selectPhones,
    selectSelectedModel,
    selectBrand,
    selectModel,
    selectDontKnowBrand,
    selectDontKnowModel,
    selectSelectedBranch,
    selectOpenImei,
    swichImei
} from "./phoneDataSlice";
import { DownVideo } from "../../components/down-video/DownVideo";
import { CategoryLine } from "../../components/category-line/CategoryLine";
import { Checkbox } from "../../components/checkbox/Checkbox";
import { openDate } from "../order-end/orderEndSlice";
import './phone-data.scss';
import useSmartHistory from "../../metrica/metrica";
interface ImeiDialogProps{
    close:()=>void
}
function ImeiDialog(props:ImeiDialogProps) {
    /*onClick={() => dispatch(brandListOpener())} */
    /* */
    return (
        <div className={`brand-list_wrapper`}>

            <div className="darked-background" onClick={props.close}></div>
            <div className="models-dialog">
            <span className="models-heading">   Где найти&nbsp;<div className="primaryText"> Imei </div></span>
         
                <div className="image-with-steps">

                    <div className="steps-container">
                        <div className="step-list">
                            <div className="step-list-item">
                                <div className="step-list-point">
                                    <div className="sing">
                                    *
                                    </div>
                                </div>
                                <div className="step-list-item-text">
                                    На задней крышке
                                    вашего телефона
                                </div>
                            </div>
                            <div className="step-list-item">
                                <div className="step-list-point">
                                <div className="sing">
                                    *
                                    </div>
                                </div>
                                <div className="step-list-item-text">
                                    Введите команду &nbsp;
                                    <b >*#06# </b> на телефоне
                                </div>
                            </div>
                            <div className="step-list-item">
                                <div className="step-list-point">
                                <div className="sing">
                                    *
                                    </div>
                                </div>
                                <div className="step-list-item-text">
                                    В меню настройки телефона
                                </div>
                            </div>
                            <div className="step-list-item">
                                <div className="step-list-point">
                                <div className="sing">
                                    *
                                    </div>
                                </div>
                                <div className="step-list-item-text">
                                    На коробке
                                    или документах
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="image-container">
                        <img src="/assets/img/imei.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}
function BrandDialog() {

    const dispatch = useAppDispatch()
    const brandText = useAppSelector(selectBrandText);
    const modelText = useAppSelector(selectModelText);
    const brandListShow = useAppSelector(selectBrandListShow);
    const modelListShow = useAppSelector(selectModelListShow);
    const brands = useAppSelector(selectBrands);
    const selectedBranch = useAppSelector(selectSelectedBranch);
    function selectBrandHandler(id: number) {
        dispatch(selectBrand(id));
    }

    function brandCloser() {
        dispatch(brandListOpener());
    }
    let branchImg= selectedBranch?.image;
    return (
        <div className={`brand-list_wrapper ${brandListShow == false ? ' hidden' : ''}`}>
            <div className="darked-background" onClick={() => dispatch(brandListOpener())}></div>
            <div className="models-dialog">

                <span className="models-heading">Выберите производителя</span>
                <div className="models">

                    <div className="models_list">
                        {brands.map(a => <div key={a.id} onClick={() => selectBrandHandler(a.id)} className={a.selected ? "model active" : "model"}>{a.name}</div>)}
                    </div>
                    <div className="model_pic_wrapper">
                        {
                            branchImg!=null ? (
                                <img src={branchImg} alt="" className="model_pic" />
                       
                            ):(<></>)
                        }
                        <button onClick={() => brandCloser()} className="main-button yellow">Далее</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export function PhoneData() {
    const phoneData = useAppSelector(selectPhoneDataState);
    const necessaryPhoneDataFilled = useAppSelector(selectNecessaryPhoneDataFilled);
    const imeiText = useAppSelector(selectImeiText);
    const brandText = useAppSelector(selectBrandText);
    const modelText = useAppSelector(selectModelText);
    const brandListShow = useAppSelector(selectBrandListShow);
    const modelListShow = useAppSelector(selectModelListShow);
    const brands = useAppSelector(selectBrands);
    const phones = useAppSelector(selectPhones);
    const selectedModel = useAppSelector(selectSelectedModel);
    const selectedBranch = useAppSelector(selectSelectedBranch);

    const dontKnowBrand = useAppSelector(selectDontKnowBrand);
    const dontKnowModel = useAppSelector(selectDontKnowModel);
    let navigate = useSmartHistory()
    const dispatch = useAppDispatch()


    function modelCloser() {
        dispatch(modelListOpener());
    }

    function selectModelHandler(id: number) {
        dispatch(selectModel(id));
    }
    let isCanContinue = dontKnowBrand || selectedModel != null;
    let openImei = useAppSelector(selectOpenImei);
    let modelImg = selectedModel?.img;
    return (<>
        <OrderStages currentStage={2} />
        {openImei?( <ImeiDialog close={()=>{dispatch(swichImei(0))}} />):(<></>)}
        <div className="above-buttons-block">
            <CategoryLine categoryText='Информация о телефоне' />

            {brandListShow ? (<BrandDialog />) : (<></>)}

            <div className={`models_wrapper ${modelListShow == false ? ' hidden' : ''}`}>
                <div className="darked-background" onClick={() => dispatch(modelListOpener())}></div>
                <div className="models-dialog">

                    <span className="models-heading">Выберите модель</span>
                    <div className="models">

                        <div className="models_list">
                            {phones.map(a => <div key={a.id} onClick={() => selectModelHandler(a.id)} className={a.selected ? "model active" : "model"}>{a.name}</div>)}
                        </div>
                        <div className="model_pic_wrapper">
                        {
                            modelImg!=null ? (
                                <img src={modelImg} alt="" className="model_pic" />
                            ):(<></>)
                        }
                            <button onClick={() => modelCloser()} className="main-button yellow">Далее</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="above-keyboard-block phone_data_layout">
                <section className="input-phone">
                    <div className="phone-data-input_line">
                        <div className="phone-data-input_wrapper imei_wrapper" onClick={() => dispatch(onChangeFocus('IMEI'))}>
                            
                            <input value={imeiText} onChange={() => { }} type="text" className={`phone-data-input ${phoneData.focusInputKey == 'IMEI' ? 'focused' : ''}  ${phoneData.dontKnowImei ? ' disabled' : ''}`}
                                placeholder={phoneData.focusInputKey == "IMEI" ? "" : "IMEI"} />
                              <div  onClick={()=>{
                                dispatch(swichImei(0))
                                }} className="where-is-imei">Где найти imei?  <img src="/assets/img/icons/info.svg"/> </div>
                        </div>
                    </div>
                    <div className="phone-data-input_line">

                        <div className="phone-data-input_wrapper" onClick={() => dispatch(onChangeFocus('phoneBrand'))}>

                            <button disabled={dontKnowBrand} onClick={() => dispatch(brandListOpener())} className={`phone-data_choose-button ${phoneData.dontKnowBrand == true ? ' disabled' : ''}`}>
                                {phoneData.selectedBrand == null ? "Производитель" : phoneData.selectedBrand.name}
                            </button>
                         
                        </div>

                    </div>
                    <div className="phone-data-input_line">

                        <div className="phone-data-input_wrapper" onClick={() => dispatch(onChangeFocus('phoneModel'))}>
                            <button disabled={dontKnowModel || dontKnowBrand || selectedBranch == null} onClick={() => dispatch(modelListOpener())} className={`phone-data_choose-button ${phoneData.dontKnowModel == true || phoneData.dontKnowBrand == true || selectedBranch == null ? ' disabled' : ''}`}>
                                {phoneData.selectedModel == null ? "Выбрать модель" : phoneData.selectedModel.name}
                            </button>
                        </div>

                    </div>
                </section>
                <div className="enter-inputs">
                    <div className="dont_know under_keyboard">
                        <div className="phone-data-input_checkbox">
                            <Checkbox checked={phoneData.dontKnowBrand} onChange={() => {

                                dispatch(dontKnowImeiSwitch())
                                dispatch(dontKnowModelSwitch())
                                dispatch(dontKnowBrandSwitch())

                            }} heading={''} color={'yellow'} >
                                <span>
                                Я не знаю информацию о телефоне,<br/>
                                но хочу оставить его на ремонт
                                </span>
                            </Checkbox>
                        </div>
                    </div>
                    <Keyboard onEnter={() => dispatch(onEnter())} continueDisabled={necessaryPhoneDataFilled ? false : true}
                        onBackspace={() => { dispatch(onBackspace()); }}
                        onKey={(a) => { dispatch(onKey(a)); }}
                        onClickContinue={() => {

                        }}
                        onClickBack={() => { navigate.push('/client-data'); }} />
                </div>

            </div>
        </div>

        <div className="defects_buttons">
            <button onClick={() => { navigate.push('/client-data'); }} className="main-button black back">Назад</button>
            <button onClick={() => {
                dispatch(openDate())
                navigate.push('/order-end')

            }} className={`main-button yellow next ${(isCanContinue ? '' : 'disabled')}`} disabled={!isCanContinue}>Далее</button>
        </div>

        <DownVideo />
    </>)

}
