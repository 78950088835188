import React, { useState } from 'react';
import { useAppSelector } from './hooks';
import {
    locationChange,
    recordStart, recordStop, selectTouchData, selectTouchEmulate, touch
} from './touchSlice';
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import userSmartHistory from "./metrica/metrica";

export interface TouchProp {
    children: React.ReactNode
}

var touchTimeout = 90000
var timer:any =null;
export function Touches(props: TouchProp) {
    const dispatch = useDispatch()
    const touchData = useAppSelector(selectTouchData)
    const touchEmulate = useAppSelector(selectTouchEmulate)
    const history = userSmartHistory()
   
    const onTouchScreen = () => {
        
        if (!touchData.recording) {
           
            dispatch(recordStart())
            if(timer!=null){
                clearTimeout(timer)
            }
            timer=setTimeout(async () => {
                
                dispatch(recordStop())
            },touchTimeout )
        }else{
            if(timer!=null){
                clearTimeout(timer)
            }
            timer=setTimeout(async () => {
                dispatch(recordStop())
            },touchTimeout )
        }
        dispatch(touch())
    }
    const onTouchMove=  (e: React.TouchEvent<HTMLElement>) => {
        e.preventDefault();
        console.log("DEBUG: touch touch screen")
        onTouchScreen()
    }
    const onClickScreen =  (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        console.log("DEBUG: touch click screen")
        onTouchScreen()
    }
    if(touchEmulate){
        
        onTouchScreen()
    }

    //const selecteouseAppSelector()
    return (
        <div onTouchStart={onTouchMove}  onClick={onClickScreen}>
            {props.children}
        </div>
    )
}
