// import { useHistory } from 'react-router-dom';
import userSmartHistory from "../../metrica/metrica";
import './top-line.scss';
export interface TopLineProps {
    sectionStyle: 'left' | 'right' | undefined;
    sectionHeading: string;
    sectionType: 'icon' | 'text';
    sectionText?: string;
    simProductId?: number | null;
    sectionIconName?: string;
    onClick?: () => void;
    onClickBack?: () => void;
    children?: React.ReactNode
}
export function TopLine(props: TopLineProps) {
    var sectionStyle = props.sectionStyle;
    var sectionType = props.sectionType;
    var sectionText = props.sectionText;
    var sectionIconName = props.sectionIconName;
    var showSim = props.simProductId!=null;
    let navigate = userSmartHistory();
    const navToSimProduct = ()=>{
        
        navigate.push(`/product/${props.simProductId}/data`);
    }
    var iconBlock = sectionType == 'icon' ? (
        <img src={"/assets/img/icons/" + sectionIconName} alt="" className="top_line_pic" />
    ) : (
        <span className="top_line_icon_content">{sectionText}</span>
    );
    return (<section className="top_line" onClick={props.onClick}>
        <div className="container">
            <div onClick={props.onClickBack} className={"top_line_icon " + sectionStyle}>
                {iconBlock}
            </div>
            <div className={`top_line_text ${sectionStyle == 'right' ? 'noicon' : ''}`}>
                {props.children}
                {props.sectionHeading}
            </div>
            {
                showSim ? (
                    <div className='sim-card-block' onClick={()=>navToSimProduct()}>
                        <img src="/assets/img/simcaard.svg" alt="" />
                        <div className='sim-text'>
                            <div className="title">Купить</div>
                            <div className="sibtitle">sim-карту</div>
                        </div>
                    </div>
                ) : (
                    <></>
                )
            }

        </div>
    </section>)
}
