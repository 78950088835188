import { useSelector } from 'react-redux';
import './support-window.scss';
import { useAppDispatch } from '../../hooks';
import { closeWindow, lastTouch, selectIsOpen } from './supportWindowSlice';
import userSmartHistory from "../../metrica/metrica";
import { useEffect } from 'react';

export interface SupportWindowProps {
    children: React.ReactNode
}

export function SupportWindow(props: SupportWindowProps) {
    let showSupport = useSelector(selectIsOpen)
    let navigate = userSmartHistory();
    const dispatch = useAppDispatch()
   
    const onClickScreen = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        console.log("DEBUG: timer click screen")
        dispatch(lastTouch())
    }

    return (
        <>
          <div  onClick={onClickScreen} >
            {props.children}
            </div>
            {showSupport ? (
                <section className={`card-methods_wrapper`}>
                    <div className="darked-background" ></div>
                    <div className="support-window">
                        <span className="support-title">Ведутся технические работы</span>
                        <img className='support-img' src='/assets/img/wrning-banner.png'></img>
                        <div className="support-phone">
                            
                        8 800 123 45 67
                        </div>

                    </div>
                </section>
            ) : (<></>)}

        </>
    )
}
