
import { createSlice,PayloadAction } from "@reduxjs/toolkit"

import { loadCategories,loadPhones, simProduct } from "../../network/actions";
import { RootState } from "../../store/store"
import { Category } from "../../sdk/client/model/BoxSdk/UIModel/category";


export enum CategoryLoadStatus{
  Loading,
  Loaded 
}
export interface HomeState {
  simProductId: number|null;
  isSaled:Boolean ;
  is_maintenance:Boolean ;
  categories: Category[];
  selectedCategory?: Category;
  categoryVersion: number;
  needReload:boolean,
  videoIndex:number,
  categoryLoadStatus:CategoryLoadStatus,
  howItWorksWrapperShow: boolean
}

const initialState: HomeState={
  categories: [],
  simProductId: null,
  
  videoIndex: 0,
  categoryVersion: 0,
  needReload: true,
  isSaled: false,
  is_maintenance: false,
  categoryLoadStatus:CategoryLoadStatus.Loading,
  howItWorksWrapperShow: false

}

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
      onChangeHowItWorksWrapperShow:(state)=>{
        state.howItWorksWrapperShow=!state.howItWorksWrapperShow;
      },
      needReload(state){
        state.needReload = true;
        state.categoryVersion = state.categoryVersion +1
      },
      selectCategory(state,action){
        state.selectedCategory = action.payload
      },

      changeVideoIndex(state){
       
        state.videoIndex =  state.videoIndex +1;
      }
        
    },
    extraReducers: (builder) => {
      // Add reducers for additional action types here, and handle loading state as needed
      builder.addCase(simProduct.fulfilled, (state, action) => {
     
        if(action.payload!=null && action.payload.inStock){
          state.simProductId = action.payload.productId
        }else{
          state.simProductId =null
        }
      });
      builder.addCase(loadCategories.fulfilled, (state, action) => {
        state.categories = action.payload.items
        state.isSaled = action.payload.is_sale
        state.is_maintenance = action.payload.is_maintenance
        state.needReload = false;
        state.categoryLoadStatus = CategoryLoadStatus.Loaded;
      })
     
     
    },
  })
  
export const { onChangeHowItWorksWrapperShow,needReload,changeVideoIndex,selectCategory } = homeSlice.actions

// export const selectAllVideos = (state: RootState) => state.home.allVideos.filter(a=>a.id!=state.home.focusedVideo.id)
// export const selectFocusedVideo = (state: RootState) => state.home.focusedVideo
// export const selectVideoShow = (state: RootState) => state.home.videoShow
export const selectIsNeedReload = (state: RootState) => state.home.categories.length==0||state.home.needReload
export const selectCategoriyVersion= (state: RootState) => state.home.categoryVersion
export const selectCategories = (state: RootState) => state.home.categories.filter((a,i)=>i<6)
export const selectSelectedCategory = (state: RootState) => state.home.selectedCategory
export const selectSimProductId = (state: RootState) => state.home.simProductId
export const selectVideoIndex = (state: RootState) => state.home.videoIndex
export const selectCategoriesLoadStatus = (state: RootState) => state.home.categoryLoadStatus
export const selectHowItWorksWrapperShow = (state: RootState) => state.home.howItWorksWrapperShow

export default homeSlice.reducer
