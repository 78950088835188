import { SimProduct } from '../../../network/actions';
import { BoxlineMessage } from '../../client/model/BoxLine/boxline_message';
import { Categories, Category ,Factory} from '../../client/model/BoxSdk/UIModel/category';
import { OrderCreateQrRs } from '../../client/model/PayQrSber/Models/order_create_qr_rs';
import { BaseService } from '../../share/rest/base.service';
import { HttpClient } from '../../share/rest/http.client';
import { RestList } from '../../share/rest/model';
export class ProductService extends BaseService
{
constructor(core:HttpClient){
	super(core)
}
	public getCategories():Promise<Categories<Category>>{
		let path = `/api/v1/product`;
		let categories  = this._get<Categories<Category>>(path)
		return categories
	}
	public getSimProduct():Promise<SimProduct>{
		let path = `/api/v1/product/sim`;
		return this._get(path)
	}
	public getPhones():Promise<RestList<Factory>>{
		let path = `/api/v1/phone/manufacturer`;
		return this._getCollectionWithInstance<Factory>(Factory, path);
	}
	public payProductQr(productId:number):Promise<OrderCreateQrRs>{
		let path = `/api/v1/product/${productId}/pay/qr`;
		return this._post<OrderCreateQrRs>(path);
	}
	public buyQrStatus(productId:number):Promise<void>{
		let path = `/api/v1/product/${productId}/status/qr`;
		return this._post<void>(path);
	}
	public payProduct(productId:number):Promise<void>{
		let path = `/api/v1/product/${productId}/buy`;
		return this._post<void>(path);
	}
	public acceptProduct(qrcode:string):Promise<BoxlineMessage>{
		let path = `/api/v1/product/${qrcode}/accept?qrcode=${qrcode}`;
		return this._post<BoxlineMessage>(path,qrcode);
	}
	public cancelBuy():Promise<void>{
		let path = `/api/v1/buy`;
		return this._delete<void>(path);
	}
}
