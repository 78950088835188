import { useAppDispatch, useAppSelector } from "../../hooks"
import { useHistory } from 'react-router';
import { CategoryLine } from '../../components/category-line/CategoryLine';
import { TopLine } from '../../components/top-line/TopLine';
import './product-page.scss';
import { cleanSpbQr } from '../sbp-window/sbpWindowSlice';
import {
    selectProduct,
    selectProductId,
    selectSuggestions,
    changeMainImage,
    selectMainProductImage,
    selectSbpShowState,
    toggleSbpDialog,
    selectCardShowState,
    toggleCardDialog,
    selectTopSuggestions,
    selectProductPrice
} from './productPageSlice';
import { TOUCH_TYPE } from "../../config/config";
import { buyProduct } from "../../network/actions";
import { SbpWindow } from "../sbp-window/sbpWindow";
import { CardWindow } from "../card-window/cardWindow";
import { PayOption } from "../../components/pay-optoins/PayOptions";
import { selectSelectedCategory } from "../home/homeSlice";
import useSmartHistory from "../../metrica/metrica";

export function ProductPage() {
    let navigate = useSmartHistory();

    const dispatch = useAppDispatch();
    let productId = useAppSelector(selectProductId)

    const isShowSpbDialog = useAppSelector(selectSbpShowState);
    const isShowCardDialog = useAppSelector(selectCardShowState);
    const selectedCatedgory = useAppSelector(selectSelectedCategory)
    const product = useAppSelector(selectProduct);
    const selectPrice = useAppSelector(selectProductPrice);
    let otherImgs:Array<string> = product?.other_img
    if (otherImgs != null && otherImgs.length > 0) {
        otherImgs = [product.main_img, ...otherImgs]
    }

    const productMainImage = useAppSelector(selectMainProductImage);
    const suggestions = useAppSelector(selectTopSuggestions);

    return (<>
        {isShowSpbDialog ? (

            <SbpWindow onChangePaymentWindowShow={() => { dispatch(toggleSbpDialog()) }} />
        ) : null}
        {isShowCardDialog ? (
            <CardWindow onChangePaymentWindowShow={() => { dispatch(toggleCardDialog()) }} />
        ) : null}
        <TopLine onClickBack={() => {
            if (selectedCatedgory != null) {
                navigate.push(`/category/${selectedCatedgory.id}/products`);
            } else {
                navigate.goBack()
            }
        }} sectionStyle='left' sectionHeading='Магазин' sectionType='icon' sectionIconName='back-button.svg' />

        {

            product == null ? null : (

                <section className="item-info">
                    <div className="item-info_half">
                        <img src={productMainImage} alt="" className="item-info_pic" />
                        {product.other_img.length != 0 ? (
                            <div className="item-info-other-pics">

                                {otherImgs.map((a, i) => (
                                    <img key={i} src={a} alt="" className="item-info-other-pic"
                                        onClick={() => dispatch(changeMainImage({
                                            img: a,
                                            product_id: productId,
                                        }))} />
                                ))}
                            </div>
                        ) : null}
                    </div>
                    <div className="item-info_half info">
                        <div className="product-info">
                            <span className="item-info_heading">{product?.name}</span>
                            <article className="item-info_description">{product?.description}</article>



                        </div>
                        <div>
                            <p className="item-info_price">{selectPrice}₽</p>

                            <PayOption paySpb={() => {
                                dispatch(toggleSbpDialog())
                            }} payCard={() => {
                                dispatch(toggleCardDialog())
                            }} />
                        </div>

                    </div>

                </section>
            )
        }
        <section className="item-list">
            <TopLine sectionStyle='left' sectionHeading='' sectionType='icon' sectionIconName='shopping-cart.svg' >
                <div> Топ<span className="primaryText"> продаваемых товаров</span>  </div>
            </TopLine>

            <div className="container">
                {suggestions.map((a, i) =>
                    <div key={i} className="item-card" onClick={() => {
                        dispatch(cleanSpbQr())
                        navigate.push(`/product/${a.id}/data`)
                    }}>
                        <span className="item-card_title">{a.name}</span>
                        <img src={a.main_img} alt="" className="item-card_pic" />
                        <div className="item-card_price">{a.price}₽</div>
                    </div>
                )}
            </div>
        </section>
    </>)
}
