import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { orderQrPayText, productQrPayText } from "../../network/actions";

import { RootState } from "../../store/store"

export interface SbpWindowState {
  qr_text: string | null
  product_id: number | null
  secret: string | null
  requestSended: Boolean 
}

const initialState: SbpWindowState = {
  qr_text: null,
  secret: null,
  product_id: null,
  requestSended:false
}

export const sbpWindow = createSlice({
  name: 'sbpWindow',
  initialState,
  reducers: {
    
    closePaymentWindow: (state) => {
      
    },

    qrPaymentShow: (state) => {
      state.qr_text = null;
      
    },
    requestSended: (state) => {
      state.requestSended = true;
    },
    nfcPaymentShow: (state) => {
     
    },
    cleanSpbQr: (state) => {
      state.qr_text = null;
      state.requestSended = false;

    }
  },
  extraReducers: (builder) => {
    builder.addCase("CLEANUP",(state)=>{
      state.qr_text = null;
      state.secret = null;
      state.product_id = null;
      state.requestSended = false;
    });
    
    builder.addCase(productQrPayText.fulfilled, (state, action: any) => {
      state.product_id = action.payload.product_id;
      state.qr_text = action.payload.qr_text;
      state.secret = null;
     
    })
    builder.addCase(orderQrPayText.fulfilled, (state, action: any) => {
      let payload = action.payload
      state.product_id = null
      state.secret = payload.secret;
      state.qr_text = payload.qr_text;
    })
  }
})

export const { qrPaymentShow,cleanSpbQr, nfcPaymentShow,closePaymentWindow,requestSended } = sbpWindow.actions

export const selectProductId = (state: RootState) => {
  let path = window.location.pathname;
  return + path.split('/')[2];
}
export const selectOrderSecret = (state: RootState) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.secret!=null&&params.secret!=""){
    console.log("Secret from query: ",params.secret)
      return params.secret;
  }
  console.log("Secret from orderStatusData: ",state.orderStatus.data)
  return state.orderStatus.data?.secret
}
export const productQrText = (state: RootState) => {
  return  state.sbpWindow.qr_text 
}
export const isRequestSendedSelector = (state: RootState) => {
  return state.sbpWindow.requestSended
}
export const sbpQrText = (state: RootState) => {
  let productId = selectProductId(state)
  let secert = selectOrderSecret(state)
  return productId == state.sbpWindow.product_id ||
    state.sbpWindow.secret == secert ? state.sbpWindow.qr_text : null
}
export default sbpWindow.reducer
