import { createAction, Middleware } from "@reduxjs/toolkit";
import { networkLayer } from "../network/client";
import { setQrScaner } from "../tools";

let qrAction = createAction<string>("scaner/qr")
var connected = false;
let qrMiddleware = (store:any) => (next:any) => (action:any) => {
 
  if(!connected ){
    connected=true;
    setQrScaner((code:string)=>{
      console.log("onQR ",code)
      networkLayer.qrService.qrHandler({qr: code} ).then(a=>{
        console.log("qr pushed ",code)
      })
     })
  }
  return next(action)
};
export default qrMiddleware;


export {qrAction}
