import { useAppDispatch, useAppSelector } from "../../hooks"

import { LoadingStatus, selectLoadingStatusState, selectQrScanState } from "./qrScanSlice"
import moment from 'moment'
import { Loading } from "../../components/loading/Loading";
import { TopLine } from "../../components/top-line/TopLine";
import { useHistory } from "react-router"
import './qr-scan.scss';
import { CategoryLine } from "../../components/category-line/CategoryLine"
import useSmartHistory from "../../metrica/metrica";
export function QrScan() {
    const qrScanLoadingStatus = useAppSelector(selectLoadingStatusState);
    const qrScan = useAppSelector(selectQrScanState)
    const dispatch = useAppDispatch()
    const navigate = useSmartHistory()
    if (qrScanLoadingStatus == LoadingStatus.Loading) {
        return <Loading />
    }

    if (qrScan == null) {
        return <Loading />
    }

    return (<>
        <TopLine onClickBack={() => { navigate.goBack() }} sectionStyle='left' sectionHeading='' sectionType='icon' sectionIconName='back-button.svg' >
        <div>
            Отсканируйте <span className="primaryText">QR-код</span>
            </div>
        </TopLine>

        <div className="qrScanImage above-buttons-block" onClick={() => { navigate.push('/order-status') }}>
            <div className="col">
                <video autoPlay loop muted  height="" className="finish-video qr-video">
                    <source className="footer-video_content" src="/assets/videos/videoqr.mp4" type='video/mp4'/>
                </video>
                   
               
            </div>
            <div className="col">
                {/* <div className="primary">
                    У меня нет<br/>
                    QR-кода заказа
                </div> */}
            </div>
        </div>
        <div className="defects_buttons">
            <button onClick={() => { navigate.push('/'); }} className="main-button black back">Назад</button>
            <button className={`main-button yellow next invisible`} >Далее</button>
        </div>
    </>)

}
