import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"
import { ClientData } from "./ClientData";

export interface ClientDataState {
  focusInputKey: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  firstPhoneNumber: string;
  secondPhoneNumber: string;
  firstPromptShow: boolean;
  secondPromptShow: boolean;
  selectedPrefer: string[];
}

const initialState: ClientDataState = {
  focusInputKey: "",
  firstName: "",
  lastName: "",
  patronymic: "",
  firstPhoneNumber: "",
  secondPhoneNumber: "",
  firstPromptShow: false,
  secondPromptShow: false,
  selectedPrefer: ["phonecall"]
}
export const clientDataSlice = createSlice({
  name: 'clientData',
  initialState,
  reducers: {
    onKey: (state, action) => {
      switch (state.focusInputKey) {
        case 'firstName':
          if (isNaN(action.payload) == true && action.payload != "." && action.payload != ",") {
            if (state.firstName == "") {
              state.firstName += action.payload.toUpperCase();
            }
            else {
              state.firstName += action.payload
            }
          }

          break
        case 'lastName':
          if (isNaN(action.payload) == true && action.payload != "." && action.payload != ",") {
            if (state.lastName == "") {
              state.lastName += action.payload.toUpperCase();
            }
            else {
              state.lastName += action.payload
            }
          }
          break
        case 'patronymic':
          if (isNaN(action.payload) == true && action.payload != "." && action.payload != ",") {
            if (state.patronymic == "") {
              state.patronymic += action.payload.toUpperCase();
            }
            else {
              state.patronymic += action.payload
            }
          }
          break

        case 'firstPhoneNumber':
          if (state.firstPhoneNumber.length <= 15) {
            if (isNaN(action.payload) == false) {
              state.firstPhoneNumber += action.payload;
              if (state.firstPhoneNumber.length == 6) {
                state.firstPhoneNumber += ')';
              }
              if (state.firstPhoneNumber.length == 10 || state.firstPhoneNumber.length == 13) {
                state.firstPhoneNumber += '-';
              }
            }
          } else {
            state.focusInputKey = "secondPhoneNumber";
            if (state.secondPhoneNumber.length < 5) {
              state.secondPhoneNumber = '+7('
            }

          }
          console.log("PHONE NUMBER: " + state.firstPhoneNumber)
          break

        case 'secondPhoneNumber':
          if (state.secondPhoneNumber.length <= 15) {
            if (isNaN(action.payload) == false) {
              state.secondPhoneNumber += action.payload;
              if (state.secondPhoneNumber.length == 6) {
                state.secondPhoneNumber += ')';
              }
              if (state.secondPhoneNumber.length == 10 || state.secondPhoneNumber.length == 13) {
                state.secondPhoneNumber += '-';
              }
            }
          } else {

          }
          break
        default:
          state.focusInputKey = 'lastName'
          if (isNaN(action.payload) == true && action.payload != "." && action.payload != ",") {
            if (state.lastName == "") {
              state.lastName += action.payload.toUpperCase();
            }
            else {
              state.lastName += action.payload
            }
          }
      };
    },
    onBackspace: (state) => {
      switch (state.focusInputKey) {
        case 'firstName':
          state.firstName = state.firstName.slice(0, -1);
          break
        case 'lastName':
          state.lastName = state.lastName.slice(0, -1);
          break
        case 'patronymic':
          state.patronymic = state.patronymic.slice(0, -1);
          break
        case 'firstPhoneNumber':
          state.firstPhoneNumber = state.firstPhoneNumber.slice(0, -1);
          break
        case 'secondPhoneNumber':
          state.secondPhoneNumber = state.secondPhoneNumber.slice(0, -1);
          break
      };

    },
    onChangeFocus: (state, action) => {
      state.focusInputKey = action.payload;
      if (action.payload == 'firstPhoneNumber') {
        if (state.firstPhoneNumber == "") {
          state.firstPhoneNumber = "+7(";
        }
      }
      if (action.payload == 'secondPhoneNumber') {
        if (state.secondPhoneNumber == "") {
          state.secondPhoneNumber = "+7(";
        }
      }
    },
    onChangePrefer: (state, action) => {
      let index = state.selectedPrefer.indexOf(action.payload);
      if (index >= 0) {
        state.selectedPrefer = state.selectedPrefer.filter(a => a != action.payload);
      }
      else {
        state.selectedPrefer.push(action.payload);
      }
    },
    onEnter: (state) => {
      switch (state.focusInputKey) {
        case 'lastName':
          state.focusInputKey = "firstName"
          break
        case 'firstName':
          state.focusInputKey = "patronymic"
          break
        case 'patronymic':
          state.focusInputKey = "firstPhoneNumber"
          if (state.firstPhoneNumber.length < 5) {
            state.firstPhoneNumber = '+7('
          }

          break
        case 'firstPhoneNumber':
          state.focusInputKey = "secondPhoneNumber"
          if (state.secondPhoneNumber.length < 5) {
            state.secondPhoneNumber = '+7('
          }


          debugger
          break
        case 'secondPhoneNumber':
          state.focusInputKey = ""
          break
      }
    },
    firstPromptOpener: (state) => {
      state.firstPromptShow = !state.firstPromptShow;
    },
    secondPromptOpener: (state) => {
      state.secondPromptShow = !state.secondPromptShow;
    }
  },
  extraReducers: (builder) => {
    builder.addCase("CLEANUP", (state) => {
      state.focusInputKey = ""
      state.firstName = ""
      state.lastName = ""
      state.patronymic = ""
      state.firstPhoneNumber = ""
      state.secondPhoneNumber = ""
      state.selectedPrefer = []
    })
  }
})

export const { onKey, onBackspace, onChangeFocus, onEnter, firstPromptOpener, secondPromptOpener, onChangePrefer } = clientDataSlice.actions
export const selectPreferState = (state: RootState) => state.clientData.selectedPrefer
export const selectClientDataState = (state: RootState) => state.clientData
export const selectFirstPromptShowState = (state: RootState) => state.clientData.firstPromptShow
export const selectSecondPromptShowState = (state: RootState) => state.clientData.secondPromptShow
export const selectIsBackToComment = (state: RootState) => state.orderDefects.defectsList.find(a => a.id == 14)?.selected ?? false;
export const selectNecessaryClientDataFilled = (state: RootState) => {
  let clientData = state.clientData;
  if (clientData.firstName == "" || clientData.firstPhoneNumber.length < 11) {
    return false;
  } else {
    return true;
  }
}

export default clientDataSlice.reducer
