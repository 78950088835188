import { useHistory } from 'react-router-dom';
import './footer.scss';
import { Support } from '../support/Support';
import useSmartHistory from '../../metrica/metrica';

export interface FooterProps{
}

export function Footer() {
    let navigate = useSmartHistory();
    return (<footer>
        <Support></Support>
        {/* <div className="footer-phone">
            <img src="assets/img/icons/call.svg" alt="" className="footer-phone_icon"/>
            <span className="footer-phone_text">+7 (843) 200-00-00</span>
        </div> */}
    </footer>)
}
