import { useSelector } from 'react-redux';
import './card-window.scss';
import { selectProductId } from '../product-page/productPageSlice';
import { productQrText, selectOrderSecret } from '../sbp-window/sbpWindowSlice';
import { nfcPayOrder, nfcPayProduct } from '../../network/actions';
import { useAppDispatch } from '../../hooks';

export interface CardWindowProps {
    onChangePaymentWindowShow: () => void;
}

export function CardWindow(props: CardWindowProps) {
    const changePaymentShowMode = () => {
       props.onChangePaymentWindowShow()
    }
    let productId = useSelector(selectProductId)

    let secret = useSelector(selectOrderSecret)
    let qrText = useSelector(productQrText)
    const dispatch = useAppDispatch();
    console.log("Pay secret is ",secret)
    if (secret != null) {
        dispatch(nfcPayOrder(secret))
    } else {
        dispatch(nfcPayProduct(productId))
    }
    return (
        <section className={`card-methods_wrapper`}>
            <div className="darked-background" ></div>
            <div className="payment-methods">
                <div className='payment-method-title'>

                Работа с терминалом оплаты
                </div>
            <div className='payment-instuction'>
           
           <div className="step-list">
               <div className="step-list-item">
                   <div className="step-list-point">1</div>
                   <div className="step-list-item-text">
                   Оплатите картой
                   </div>
               </div>
               <div className="step-list-item">
                   <div className="step-list-point">2</div>
                   <div className="step-list-item-text">
                   Дождитесь выдачи чека
                   </div>
               </div>
               <div className="step-list-item">
                   <div className="step-list-point">3</div>
                   <div className="step-list-item-text">
                   
                   Для отмены на терминале  <br/> нажмите
                   <img src='/assets/img/icons/redbutton.png'/>
                   </div>
               </div>
           </div>
           <div className='pinpad-image'>
               <img src="/assets/img/icons/pinpad.png" alt="" />
           </div>
           
       </div>
            </div>
           
        </section>
    )
}
