import { createAction } from "@reduxjs/toolkit";
import { ILiteHubClient, LiteHub } from "../network/signalr/lite.hub";
import { host } from '../network/host'
import { createOrderResult } from "../features/order-end/orderEndSlice";
import { OrderState } from "../features/order-status/orderStatusSlice";
import { buyPaymentFailed,  loadCategories, orderPaymentFailed, orderPaymentSuccess } from "../network/actions";
import { needReload } from "../features/home/homeSlice";
import { lastTouch } from "../features/licence-window/licenceWindowSlice";
import { openWindow } from "../features/saled-window/saledWindowSlice";

export interface OrderModel { }
export interface UserModel { }

export interface CreateOrderResponse{
    order_number: string
}
export interface BoxManagerOrderData {
    order: OrderModel
    user: UserModel
}
let liteHub = new LiteHub()
var orderStatus = createAction<BoxManagerOrderData>('order/status')
var orderPayed = createAction<BoxManagerOrderData>('order/status')
var buyResult = createAction<boolean>('buy/result')

var orderBoxOpened = createAction('box/opened')
var conencted = false
let connectionMiddleware =(history:any) => (store: any) => (next: any) => (action: any) => {
    // console.log(`ACTION: ${action.type} ${JSON.stringify(action.payload)}`)
    // //store.dispatch({type:"qr",payload:code})
    
    if (!conencted){
        conencted = true;
        liteHub.start(host, <ILiteHubClient>{
            BuyResult: (success: boolean) => {
                debugger
                if(success){
                    history.push("/finish-buy")

                } else {
                    next(buyPaymentFailed())
                }
            },
            OrderPayResult: (success: boolean) => {
               
                if(success){
                    next(orderPaymentSuccess())
                   
                    history.push("/finish-order")
                } else {
                    next(orderPaymentFailed())
                }
            },
            SaleStart:(percent:number)=>{
                var action  = needReload();
                next(action)
                next(openWindow(percent))
            },
            SupportStart:()=>{
                
            },
            BuyPreResult: (success: boolean) => {
                
            },
            OrderPayPreResult: (success: boolean) => {
               
                
            },
            OrderBoxOpened: () => {
                console.log("SignalR: OrderBoxOpened ")
                next(orderBoxOpened())
            },
            CreateOrderResult: (order: CreateOrderResponse) => {
                console.log("SignalR: CreateOrderResult ",order)
                next(createOrderResult(order))
                history.push('/finish');
            },
            OrderPayed: (order: BoxManagerOrderData) => {
                console.log("SignalR: OrderPayed ")
                //history.push("/order-status")
                next(orderPayed(order))
            },
            OrderStatus: (order: OrderState) => {
                console.log("SignalR: OrderStatus ")
                next(lastTouch())
                next({type:"CLEANUP"})
               
               
                next(orderStatus(order))
                history.push("/order-status")
              
            },
            ReloadCatalogs: () => {
                console.log("SignalR: ReloadCatalogs ")
                var action  = needReload();
                next(action)
               
            },
            ReloadPage: () => {
                console.log("SignalR: ReloadPage ")
               
                window.location.href="/"
            },
            ToHome: () => {
                console.log("SignalR: OrderStatus ")    
                history.push("/")
              
            }
        })
    }
    
    next(action);
}

export { connectionMiddleware }
