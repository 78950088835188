import { useHistory } from 'react-router';
import { CategoryLine } from '../../components/category-line/CategoryLine';
import { TopLine } from '../../components/top-line/TopLine';
import { TOUCH_TYPE } from '../../config/config';
import './service.scss';
import useSmartHistory from '../../metrica/metrica';

export function Service() {
    let navigate = useSmartHistory();
    let onPrevPage = (e:any) =>{
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        navigate.push('/')
    }
    return (<>
    <TopLine sectionStyle='left' sectionText='Сервис' sectionHeading='' sectionType='text'>
        
    </TopLine>
    <CategoryLine categoryText='Выберите раздел'/>
    <section className="service_links">
        <a href="" className="service_link">
            <span className="service_link_heading">Забрать курьеру</span>
            <img src="assets/img/icons/forward-button-white.svg" alt="" className="service_link_pic reversed" />
        </a>
        <a href="" className="service_link">
            <span className="service_link_heading">Оставить курьеру</span>
            <img src="assets/img/icons/forward-button-white.svg" alt="" className="service_link_pic" />
        </a>
        <a href="" className="service_link">
            <span className="service_link_heading">Работа с товаром</span>
            <img src="assets/img/icons/shopping-cart-white.svg" alt="" className="service_link_pic" />
        </a>
        <div className="build-version">
            <div>dev</div>
        </div>
    </section>
    <section className="service_back-button">
        <button onClick={onPrevPage} onTouchStart={onPrevPage} className="main-button black">Назад</button>
    </section></>)
}
