
import './pay-options.scss';

interface PayOptionProps {
    paySpb: () => void;
    payCard: () => void;
}

export function PayOption(optons: PayOptionProps) {
    return (
        <div className='pay-block'>
            Выберите способ оплаты:
            <div className='pay-options'>
                <div className='pay-option sbp' onClick={optons.paySpb}>
                    <img src="/assets/img/icons/sbpnew.png" alt="" />
                </div>
                <div className='pay-option card' onClick={optons.payCard}>
                    <img src="/assets/img/icons/cardnew.png" alt="" />
                </div>
            </div>
        </div>
    )
}
