import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"
export interface SaledWindowState {
  isSaled: boolean,
  percent: number,
}

const initialState: SaledWindowState = {
  isSaled: false,
  percent: 0,
}

export const saledWindow = createSlice({
  name: 'saledWindow',
  initialState,
  reducers: {
    closeWindow: (state) => {
      state.isSaled = false
    },
    openWindow: (state,action) => {
      state.percent = action.payload
      state.isSaled = true
    },
    timeTick:(state)=>{
      
    },
   
    lastTouch:(state)=>{
    
    },
   
  },
  extraReducers: (builder) => {
    builder.addCase("CLEANUP", (state) => {

    });

  }
})

export const { closeWindow,lastTouch, timeTick, openWindow } = saledWindow.actions



export const selectIsOpen = (state: RootState) => {
  return state.saledWindow.isSaled
}
export const selectPercent = (state: RootState) => {
  return state.saledWindow.percent
}


export default saledWindow.reducer
