import { useSelector } from 'react-redux';
import './saled-window.scss';
import { useAppDispatch } from '../../hooks';
import { closeWindow, lastTouch, selectIsOpen, selectPercent } from './saledWindowSlice';
import userSmartHistory from "../../metrica/metrica";
import { useEffect } from 'react';

export interface SaledWindowProps {
    children: React.ReactNode
}

export function SaledWindow(props: SaledWindowProps) {
    let showSaled = useSelector(selectIsOpen)
    let percent = useSelector(selectPercent)

    let navigate = userSmartHistory();
    const dispatch = useAppDispatch()
    const onTouchScreen= async (e: React.TouchEvent<HTMLElement>) => {
        e.preventDefault();
        console.log("DEBUG: timer touch screen")
        dispatch(lastTouch())
    }
    const onClickScreen = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        console.log("DEBUG: timer click screen")
        dispatch(lastTouch())
    }

    return (
        <>
          <div  onClick={onClickScreen} >
            {props.children}
            </div>
            {showSaled ? (
                <section className={`card-methods_wrapper`}>
                    <div className="darked-background" ></div>
                    <div className="saled-window">
                        <span className="saled-title">Вам предоставлена скидка</span>
                        <div className="sale-percent">{percent}%</div>
                        <div className="saled-buttons">
                            
                            <div className="main-button yellow yes" onClick={()=>{
                                dispatch(closeWindow())
                            }}>Далее</div>
                        </div>

                    </div>
                </section>
            ) : (<></>)}

        </>
    )
}
