


let host = ''
var win = window  as any

if (win.__REACT_DEVTOOLS_GLOBAL_HOOK__!=null) {
   host  ='http://remlite.smartapi.ru'
}
export { host}
