import { useHistory } from 'react-router';
import { CategoryLine } from '../../components/category-line/CategoryLine';
import { OrderStages } from '../../components/order-stages/OrderStages';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectOpenDate, selectOrderModel, selectLicenseAgree, onChangeAgree, openDate, selectRepairMode } from './orderEndSlice';
import { useDispatch } from 'react-redux';
import { Checkbox } from "../../components/checkbox/Checkbox";
import './order-end.scss';
import { TOUCH_TYPE } from '../../config/config';
import { createOrder } from '../../network/actions';
import { lastTouch } from '../licence-window/licenceWindowSlice';
import { touch } from '../../touchSlice';
import useSmartHistory from '../../metrica/metrica';

export function OrderEnd() {
    let navigate = useSmartHistory()
    let dispatch = useAppDispatch();
    let order = useAppSelector(selectOrderModel)
    let openDateTime = useAppSelector(selectOpenDate)
    let licenseAgree = useAppSelector(selectLicenseAgree)
    let iframePressed = (e: any)=> {
        console.log('key pressed'); // This is not invoking
        debugger;
    }

    let iframeLoaded = (e: any) => {
        let iframe:HTMLIFrameElement = e.target 
     
        let contentWindow =  iframe.contentWindow
        if(contentWindow!=null&&contentWindow.document){
           
            contentWindow.document.addEventListener('click',() =>{
                dispatch(lastTouch())
                dispatch(touch())
            });
            contentWindow.document.addEventListener('touchstart',() =>{
               dispatch(lastTouch())
               dispatch(touch())
            });
        }
        
    }
    
   
    let onNextPage = (e: any) => {
        if (e.type != TOUCH_TYPE) {
            return
        }

        let now = new Date()
        if (openDateTime == null || openDateTime != null && now.getTime() - openDateTime > 2000) {
            dispatch(createOrder(order)).then(() => {
                dispatch({ type: "CLEANUP" })
                navigate.push('/finish-cards')
            })
        }
    }
    let isCanContinue =licenseAgree;
    const onTouchMove= async (e: React.TouchEvent<HTMLElement>) => {
        e.preventDefault();
    }
    return (
        <>
            <OrderStages currentStage={3} />
            <div className="above-buttons-block">
            <CategoryLine categoryText='Завершение оформления' />
            
                <div className="order-end_warning">
                    <iframe onLoad={(e) => iframeLoaded(e)}
                     width="90%" height="625px"
                     
                     src="/api/v1/terms" 
                    //  src="/assets/html/terms.html" 
                     />
                </div>
                <div className="order-end_agree">
                    <Checkbox checked={licenseAgree} onChange={() => { dispatch(onChangeAgree()) }} heading={'Я принимаю условия соглашения'} color={'black'} />
                </div>
            </div>


            <div className="defects_buttons">
            <button onClick={() => { navigate.push('/phone-data'); }} className="main-button black back">Назад</button>
            <button onClick={onNextPage} className={`main-button yellow next ${(isCanContinue ? '' : 'disabled')}`} disabled={!isCanContinue}>Далее</button>
        </div>
            {/* <div className="defects_buttons">
                <button onClick={() => navigate.push('/phone-data')} className="main-button black">Назад
                </button>
                <button disabled={licenseAgree == false} onClick={onNextPage} className={`main-button yellow ${licenseAgree ? '' : ' disabled'}`}>Далее</button>
            </div> */}

        </>

    )

}
