import { OrderState } from '../../../features/order-status/orderStatusSlice';
import { FullLiteOrderModel } from '../../client/model/AssemblyModels/full_lite_order.model';
import { OrderCreateQrRs } from '../../client/model/PayQrSber/Models/order_create_qr_rs';
import { OrderStatusRequestQrRs } from '../../client/model/PayQrSber/Models/order_status_request_qr_rs';
import { BaseService } from '../../share/rest/base.service';
import { HttpClient } from '../../share/rest/http.client';
export class OrderService extends BaseService
{
constructor(core:HttpClient){
	super(core)
}
	public takeOrder(secret:string):Promise<void> {
		let path = `/api/v1/order/${secret}/take?secret=${secret}`;
		return this._post<void>(path,secret);
	}
	public createOrder(order:FullLiteOrderModel):Promise<void>{
		let path = `/api/v1/order/create`;
		return this._post<void>(path,order);
	}
	public canselOrder():Promise<void>{
		let path = `/api/v1/order`;
		return this._delete<void>(path);
	}
	public payOrderQr(secret:string):Promise<OrderCreateQrRs>{
		let path = `/api/v1/order/pay/qr?secret=${secret}`;
		return this._post<OrderCreateQrRs>(path,secret);
	}
	public spbDialogClose():Promise<any>{
		let path = `/api/v1/spb/dialog/close`;
		return this._get(path);
	}
	public payOrderNfc(secret:string):Promise<OrderCreateQrRs>{
		let path = `/api/v1/order/pay/nfc?secret=${secret}`;
		return this._post<OrderCreateQrRs>(path,secret);
	}
	public payOrderQRStatus(secret:string):Promise<OrderState>{
		let path = `/api/v1/order/pay/status?secret=${secret}`;
		return this._get<OrderState>(path);
	}
	public orderStatus(secret:string):Promise<OrderState>{
		let path = `/api/v1/order/status?secret=${secret}`;
		return this._get<OrderState>(path);
	}
	public orderTest():Promise<void>{
		let path = `/api/v1/order/create/test`;
		return this._post<void>(path);
	}
}
