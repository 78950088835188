import { Product } from '../../../../client/model/BoxSdk/UIModel/product';
export class Category {
	id!: number;
	name!: string;
	img!: string;
	disabled!: boolean;
	min_price!: number;
	products!: Array<Product>;
}
export interface Categories<T>{
	is_sale:boolean
	is_maintenance:boolean
	items:[Category]
}

export class PhoneServerModel {
	id!: number;
	image!: string;
	name!: string;
	additional_name?: string;
}

export class Factory {
	id!: number;
	phone_model!: PhoneServerModel[];
	name!: string;
	image!: string;
}
