import { createSlice } from "@reduxjs/toolkit"

import { buyProduct, buyPaymentFailed } from "../../network/actions";
import { RootState } from "../../store/store"
import { Product } from "../../sdk/client/model/BoxSdk/UIModel/product";


export interface ProductPage {

}
export interface ProductPageState {
  sbpWindowShow: boolean;
  cardWindowShow: boolean;
  secret: string | null
  main_img: string | null
  product_id: number | null

}
const initialState: ProductPageState = {
  sbpWindowShow: false,
  cardWindowShow: false,
  secret: null,
  main_img: null,
  product_id: null,
}
export const productPageSlice = createSlice({
  name: 'productPage',
  initialState,
  reducers: {
    changeMainImage: (state, action) => {
      state.product_id = action.payload.product_id
      state.main_img = action.payload.img;
    },
    toggleSbpDialog: (state) => {
      state.sbpWindowShow = !state.sbpWindowShow;
    },
    toggleCardDialog: (state) => {
      state.cardWindowShow = !state.cardWindowShow;
    },
  },

  extraReducers: (builder) => {
    builder.addCase("CLEANUP", (state, action: any) => {
      state.product_id = null
      state.main_img = null
      state.secret = null
      state.cardWindowShow = false;
      state.sbpWindowShow = false
    })
    builder.addCase(buyPaymentFailed, (state) => {
      state.cardWindowShow = false;
      state.sbpWindowShow = false;
    })
  }

})

export const { changeMainImage, toggleSbpDialog, toggleCardDialog } = productPageSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectProductId = (state: RootState) => {
  let path = window.location.pathname;
  return + path.split('/')[2];
}

export const selectProductSecret = (state: RootState) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.secret != null && params.secret != "") {
    return params.secret;
  }
  console.log("Secret from orderStatusData: ", state.orderStatus.data)
  return state.productPage.secret
}
export const productPageState = (state: RootState) => state.productPage

export const selectSbpShowState = (state: RootState) => state.productPage.sbpWindowShow
export const selectCardShowState = (state: RootState) => state.productPage.cardWindowShow
export const selectWorkshopPhone = (state: RootState) => state.orderEnd.workshopPhone

export const selectSuggestions = (state: RootState) => {
  let id = selectProductId(state)
  return state.home.categories
    .flatMap(a => a.products).sort(a => Math.floor(Math.random()) * 2 - 2)
    .filter((a, i) => a.id != id)
    .filter((a, i) => i < 6)
}
export const selectTopSuggestions = (state: RootState) => {
  let id = selectProductId(state)
  return state.home.categories
    .flatMap(a => a.products).sort(a => Math.floor(Math.random()) * 2 - 2)
    .filter((a, i) => a.id != id)
    .filter((a, i) => i < 3)
}

export const selectProduct = (state: RootState): Product => {
  let id = selectProductId(state)
  var products = state.home.categories.flatMap(a => a.products);
  let product =  products.find(a => a.id == id)
  return product??products[0]
}
export const isSaled = (state: RootState):Boolean=>{
  return state.home.isSaled
}
export const selectProductPrice = (state: RootState): number => {
  let product = selectProduct(state)
  let isSale = isSaled(state)
  return isSale?product.discount_price:  product.price 
}
export const selectMainProductImage = (state: RootState) => {
  let id = selectProductId(state)
  if (state.productPage.product_id == id) {
    return state.productPage?.main_img ?? selectProduct(state)?.main_img;
  }
  return selectProduct(state)?.main_img;
}


export default productPageSlice.reducer
