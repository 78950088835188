import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DownVideo } from "../../components/down-video/DownVideo";
import { TopLine } from "../../components/top-line/TopLine";
import './repair-options.scss';
import { RepairMode, repairMode } from "../order-end/orderEndSlice";
import useSmartHistory from "../../metrica/metrica";

export function RepairOptions() {
    let navigate = useSmartHistory();
    let dispatch = useDispatch()
    let toNext = (mode: RepairMode) => {

        dispatch({
            type: "CLEANUP",
            mode: mode
        })
        //  dispatch(repairMode(mode))
        navigate.push('/order-defects')


    }
    return (<>
        <TopLine sectionStyle='left' sectionText='!' sectionHeading='' sectionType='text' >
            <div> Выберите условия <span className="primaryText">ремонта</span> </div>
        </TopLine>
        <div className="above-buttons-block">
            <section className="repair-options above-buttons-block">

                <div onClick={() => {
                    toNext(RepairMode.Cheper)
                }} className="repair-option cheper">
                    <div className="icon_contianer">
                        <img src="assets/img/piggy-bank.png"
                            alt="" className="repair-option_icon" />
                    </div>
                    <h2 className="repair-option_heading">
                        Подешевле
                    </h2>
                    <div className="repair_option_list">
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">590 р.</div>
                            <div className="repair_option_item_text">цена услуги*</div>
                        </div>
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">48</div>
                            <div className="repair_option_item_text">часов на ремонт</div>
                        </div>
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">15</div>
                            <div className="repair_option_item_text">дней гарантия</div>
                        </div>
                    </div>
                    <div className="notion">
                        *без учета стоимости запчастей
                    </div>
                    {/*                   
                <ul className="repair-option_list">
                    <li className="repair-option_line">Ремонт за <i>48</i> часов</li>
                    <li className="repair-option_line">Гарантия <i>15</i> дней</li>
                    <li className="repair-option_line">Личный менеджер</li>
                    <li className="repair-option_line">Доставка до терминала</li>
                    <li className="repair-option_line">Скидка на следующий ремонт</li>
                </ul>
                <img src="assets/img/icons/click.svg" alt="" className="repair-option_icon" /> */}
                </div>
                <div onClick={() => {
                    toNext(RepairMode.Faster)


                }} className="repair-option faster">

                    <div className="icon_contianer">
                        <img src="assets/img/rocket.png"
                            alt="" className="repair-option_icon" />
                    </div>
                    <h2 className="repair-option_heading">
                        Побыстрее
                    </h2>
                    <div className="repair_option_list">
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">990 р.</div>
                            <div className="repair_option_item_text">цена услуги*</div>
                        </div>
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">24</div>
                            <div className="repair_option_item_text">часов на ремонт</div>
                        </div>
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">30</div>
                            <div className="repair_option_item_text">дней гарантия</div>
                        </div>
                    </div>
                    <div className="notion">
                        *без учета стоимости запчастей
                    </div>
                </div>

            </section>
        </div>

        <div className="defects_buttons">
            <button onClick={() => { navigate.push('/') }} className="main-button black back">Назад</button>
            <button className={`main-button yellow next invisible`} >Далее</button>
        </div>

        <DownVideo />
    </>)

}
