
import './support.scss';
export function Support(){
    return (
        <div className='support_line'>
            <div>Служба поддержки:&nbsp;&nbsp;&nbsp; </div>
            <div>8 (843) 214-38-32 </div>
        </div>
    )
}
